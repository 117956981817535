<template>
  <div class="login_container">
    <div class="login_box">
      <div class="avatar_box">
        <img src="@/assets/logo.png" class="img_logo" />
      </div>
      <el-form
        class="login_form"
        :model="loginForm"
        :rules="loginFormRules"
        ref="loginFormRef"
      >
        <el-form-item prop="username">
          <el-input
            prefix-icon="el-icon-user"
            v-model="loginForm.username"
            placeholder="请输入用户名"
            ref="username"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            prefix-icon="el-icon-lock"
            v-model="loginForm.password"
            type="password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-row>
            <el-col :span="6">
              <identify
                :identifyCode="identifyCode"
                @update="refreshCode"
              ></identify>
            </el-col>
            <el-col :span="18">
              <el-input
                v-model="loginForm.code"
                placeholder="请输入验证码"
                @keyup.enter.native="login"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import identify from "../../components/Identify.vue";

export default {
  data() {
    var checkCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        if (
          this.loginForm.code.toLowerCase() !== this.identifyCode.toLowerCase()
        ) {
          callback(new Error("验证码错误"));
        } else {
          callback();
        }
      }
    };
    return {
      //登录表单的数据对象
      loginForm: {
        username: "",
        password: "",
        code: "",
      },
      identifyCodes: "1234567890abcdefjhijklinopqrsduvwxyz",
      identifyCode: "",
      //表单的验证规则对象
      loginFormRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ validator: checkCode, trigger: "blur" }],
      },
    };
  },
  methods: {
    //重置登录表单
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    login() {
      this.$refs.loginFormRef.validate((valid) => {
        window.console.log(this.loginForm)
        if (!valid) return;
        this.$http.post("/User/Login",this.loginForm).then((res) => {
         
          this.$message({
            message: "登录成功！",
            type: "success",
          });
          window.localStorage.setItem("token", res.data.data);

          this.$router.push('/management')
        });
      });
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
  },
  mounted() {
    this.$refs.username.focus();
    // 初始化验证码
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  components: {
    identify,
  },
};
</script>
<style scoped>
.login_container {
  background-color: #fff;
  height: 100%;
}
.login_box {
  width: 450px;
  height: 350px;
  background-color: #409eff;
  border-radius: 5px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 20%);
}
.avatar_box {
  height: 130px;
  width: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #409eff;
}
.img_logo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #eee;
}
.btns {
  display: flex;
  justify-content: flex-end;
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
</style>